import {Image} from "../types/Image";
import {Day} from "../types/Day";
import {DateTime} from "luxon";

export function groupImagesByDay(images: Image[]): Day[] {
    let days: Day[] = [];

    for (const image of images) {
        const dayTitle = convertTimestampToDayString(image.timestamp);

        const existingDayIndex = days.findIndex(day => day.title === dayTitle);

        if (existingDayIndex === -1) {
            days.push({
                title: dayTitle,
                images: [image]
            })
        } else {
            days[existingDayIndex].images.push(image);
        }
    }

    return days;
}

/**
 * Converts the UNIX Timestamp to a string that represents a specific timestamp from 9am to 9am the next day
 * This is because many images will be taken during the night and into the next day
 */
function convertTimestampToDayString(timestamp: number): string {
    const datetime = DateTime.fromSeconds(timestamp);

    if (datetime.hour > 9) {
        const nextDay = datetime.plus({day: 1});

        return formatDayRange(datetime, nextDay);
    } else {
        const previousDay = datetime.minus({day: 1});

        return formatDayRange(previousDay, datetime);
    }
}

function formatDayRange(firstDay: DateTime, lastDay: DateTime): string {
    if (firstDay.month === lastDay.month) {
        return firstDay.toFormat('dd.') + ' - ' + lastDay.toFormat('dd. LLL yyyy');
    }

    if (firstDay.year === lastDay.year) {
        return firstDay.toFormat('dd. LLL') + ' - ' + lastDay.toFormat('dd. LLL yyyy');
    }

    return firstDay.toFormat('dd. LLL yyyy') + ' - ' + lastDay.toFormat('dd. LLL yyyy');
}