import {Status} from "../types/Status";

type StatusBannerProps = {
    status: Status
}

export default function StatusBanner({status}: StatusBannerProps) {
    if (status === Status.loading) {
        return (
            <div className={'alert info'} aria-busy="true">Laden...</div>
        );
    }

    if (status === Status.error) {
        return (
            <div className={'alert error'}>Es ist ein Fehler aufgetreten</div>
        );
    }

    if (status === Status.noResults) {
        return (
            <div className={'alert info'}>Es wurden keine Resultate gefunden</div>
        );
    }

    if (status === Status.success) {
        return (
            <div className={'alert success'}>Erfolg</div>
        );
    }

    return (<></>);
}