import {JSX, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as api from "../utilities/Api";
import {AuthenticationContext} from "../App";
import {AuthenticationContextType} from "../types/Authentication";
import {Status} from "../types/Status";
import StatusBanner from "../components/StatusBanner";

export default function Login(): JSX.Element {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [status, setStatus] = useState<Status>(Status.ready);

    const {setAuthentication} = useContext<AuthenticationContextType>(AuthenticationContext);

    const navigate = useNavigate();

    const login = () => {
        setStatus(Status.loading);

        api.login(username, password)
            .then(jwt => {
                setAuthentication({
                    jwt,
                    username
                })
                setStatus(Status.ready);

                navigate('/admin');
            })
            .catch(error => {
                console.error(error);
                setPassword('');
                setStatus(Status.error);
            });
    }

    return (
        <div className={'login'}>
            <h1>Login</h1>

            <StatusBanner status={status} />

            <input value={username}
                   onChange={e => setUsername(e.target.value)}
                   placeholder={'Benutzer'}
            />

            <input value={password}
                   onChange={e => setPassword(e.target.value)}
                   placeholder={'Passwort'}
                   type={'password'}
            />

            <button onClick={login} disabled={!username.length || !password.length}>Login</button>

            <button onClick={() => navigate('/')}>Zurück</button>
        </div>
    )
}