import {Image} from "../types/Image";
import {Member} from "../types/Member";
import {Summary} from "../types/Summary";

function getBase(): string {
    // this allows the cypress tests to override the base, i have found no other way to do this
    if (window.__RUNTIME_CONFIG__.CONTEXT !== 'Production') {
        const urlParams = new URLSearchParams(window.location.search);
        const base = urlParams.get('overrideApiBase')
        if (base) {
            return base.slice(0, -1); // trim the slash at the end
        }
    }

    return window.__RUNTIME_CONFIG__.API_BASE;
}

export async function loadImages(jwt: string): Promise<Image[]> {
    const response = await fetch(getBase() + '/api/v1/image', {
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error('Could not load images');
}

export async function sendImage(imageDataUrl: string): Promise<boolean> {
    const response = await fetch(getBase() + '/api/v1/image', {
        method: 'POST',
        body: JSON.stringify({
            image: imageDataUrl,
            user_agent: window.navigator.userAgent
        })
    });

    if (!response.ok) {
        console.error(await response.text());
    }

    return response.ok;
}

export async function deleteImage(id: number, jwt: string): Promise<boolean> {
    const response = await fetch(getBase() + '/api/v1/image/' + id, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    });

    if (!response.ok) {
        console.error(await response.text());
    }

    return response.ok;
}

export async function loadMembers(jwt: string): Promise<Member[]> {
    const response = await fetch(getBase() + '/api/v1/member', {
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    })

    if (response.ok) {
        return await response.json();
    }

    throw new Error('Could not load members');
}

export async function addMember(member: Member, jwt: string): Promise<boolean> {
    const response = await fetch(getBase() + '/api/v1/member', {
        method: 'POST',
        body: JSON.stringify(member),
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    });

    if (!response.ok) {
        console.error(await response.text());
    }

    return response.ok;
}

export async function deleteMember(member: Member, jwt: string): Promise<boolean> {
    const response = await fetch(getBase() + '/api/v1/member/' + member.name, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    });

    if (!response.ok) {
        console.error(await response.text());
    }

    return response.ok;
}

export async function login(username: string, password: string): Promise<string> {
    const response = await fetch(getBase() + '/api/v1/login', {
        method: 'POST',
        body: JSON.stringify({
            username: username,
            password: password
        })
    })

    const jwt = await response.text();

    if (!response.ok || !jwt.length) {
        throw new Error('authentication failed')
    }

    return jwt;
}

export async function sendSummary(summary: Summary, jwt: string): Promise<boolean> {
    const response = await fetch(getBase() + '/api/v1/summary/', {
        method: 'POST',
        body: JSON.stringify(summary),
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    });

    if (!response.ok) {
        console.error(await response.text());
    }

    return response.ok;
}