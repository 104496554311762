import {JSX, useContext, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import StatusBanner from "../components/StatusBanner";
import {Status} from "../types/Status";
import * as api from "../utilities/Api";
import {Summary} from "../types/Summary";
import {AuthenticationContextType} from "../types/Authentication";
import {AuthenticationContext} from "../App";


export default function SummaryCreation(): JSX.Element {

    const [status, setStatus] = useState<Status>(Status.ready);

    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const {authentication} = useContext<AuthenticationContextType>(AuthenticationContext);

    // get the selected images passed by the navigation
    const {state} = useLocation();
    const {selectedImages}: {selectedImages: number[]} = state;

    const navigate = useNavigate();

    const send = () => {
        setStatus(Status.loading);

        const summary: Summary = {
            subject,
            message,
            images: selectedImages
        }

        api.sendSummary(summary, authentication?.jwt ?? '').then(
            result => {
                if (result) {
                    setStatus(Status.success);
                } else {
                    setStatus(Status.error);
                }

                setSubject('');
                setMessage('');
            },
            error => {
                console.error(error);
                setStatus(Status.error);
            }
        );
    }

    return (
        <div className={'summary'}>
            <h1>Summary</h1>

            <StatusBanner status={status} />

            <p>Anzahl Bilder: {selectedImages.length}</p>

            <fieldset>
                <label>
                    Betreff
                    <input value={subject}
                           name={'subject'}
                           onChange={e => setSubject(e.target.value)}
                    />
                </label>
                <label>
                    Nachricht
                    <textarea value={message}
                              name={'message'}
                              onChange={e => setMessage(e.target.value)}
                    />
                </label>
            </fieldset>

            <button onClick={send} disabled={!subject.length || !message.length}>Versenden</button>
            <button onClick={() => navigate('/admin')}>Zurück</button>
        </div>
    )
}