import {Member} from "../types/Member";
import {useState} from "react";

type MemberFormProps = {
    createCallback: (member: Member) => void
}

export default function MemberForm({createCallback}: MemberFormProps) {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const createMember = () => {
        const member: Member = {
            name: name,
            email: email
        }

        createCallback(member);

        setName('');
        setEmail('');
    }

    const isValid = (): boolean => {
        return name.length > 0 && email.length > 0;
    }

    return (
        <details className={'memberForm'}>
            <summary>Neues Mitglied hinzufügen</summary>
            <p>
                <input value={name}
                       onChange={e => setName(e.target.value)}
                       placeholder={'Name'}
                />

                <input value={email}
                       onChange={e => setEmail(e.target.value)}
                       placeholder={'E-Mail'}
                       type={'email'}
                />

                <button onClick={createMember} disabled={!isValid()}>Erstellen</button>
            </p>
        </details>
    )
}