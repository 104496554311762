import {Member} from "../types/Member";
import {useState} from "react";

type MemberProps = {
    member: Member,
    deleteCallback: (member: Member) => void
}

export default function MemberEntry({member, deleteCallback}: MemberProps) {

    const [dialog, setDialog] = useState<boolean>(false);

    const deleteMember = (member: Member): void => {
        deleteCallback(member);
        setDialog(false);
    }

    return (
        <article className={'member'}>
            <header>{member.name}</header>
            {member.email}
            <footer>
                <button onClick={() => setDialog(true)}>Löschen</button>
            </footer>

            <dialog open={dialog}>
                <article>
                    <h3>Soll das Mitglied gelöscht werden?</h3>
                    <footer>
                        <button onClick={() => setDialog(false)} className={'secondary'}>Abbrechen</button>
                        <button onClick={() => deleteMember(member)}>Bestätigen</button>
                    </footer>
                </article>
            </dialog>
        </article>
    )
}