
type DropdownProps = {
    name: string,
    options: {
        key: string,
        label: string
    }[],
    callback: (selected: string) => void
}

export default function Dropdown({name, options, callback}: DropdownProps) {
    return (
        <details role="list">
            <summary aria-haspopup="listbox" role="button">
                {name}
            </summary>
            <ul role="listbox">
                {options.map(({key, label}) => {
                    return <li onClick={() => callback(key)} key={key}>{label}</li>
                })}
            </ul>
        </details>
    );
}