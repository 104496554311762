import Video from "../components/Video";
import {useNavigate} from "react-router-dom";
import {JSX} from "react";

export default function Home(): JSX.Element {

    const navigate = useNavigate();

    return (
        <div className={'home'}>
            <Video />

            <button onClick={() => navigate('/login')}>Login</button>
        </div>
    )
}