import {useEffect, useRef, useState} from "react";
import Dropdown from "./Dropdown";
import * as api from "../utilities/Api";
import {Status} from "../types/Status";
import StatusBanner from "./StatusBanner";

export default function Video() {

    const [status, setStatus] = useState<Status>(Status.loading);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        // only load the devices once the camera is active, otherwise the labels will be empty
        if (status === Status.ready) {
            loadDevices();
        }
    }, [status]);

    useEffect(() => {
        const loadVideo = async (video: HTMLVideoElement) => {
            let settings: MediaStreamConstraints = {
                video: {
                    width: {
                        ideal: window.innerWidth
                    }
                }
            }

            if (selectedDevice !== null) {
                // @ts-ignore => video is set in the previous statement
                settings.video.deviceId = selectedDevice
            }

            video.srcObject = await navigator.mediaDevices.getUserMedia(settings);

            await video.play();
        };

        if (videoRef.current !== null) {
            loadVideo(videoRef.current)
                .then(
                    () => {
                        setStatus(Status.ready)
                    },
                    error => {
                        console.error(error);
                        setStatus(Status.error);
                    }
                )
        }
    }, [videoRef, selectedDevice]);

    const loadDevices = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === "videoinput");
                setDevices(videoDevices);
            })
            .catch(error => {
                setStatus(Status.error);
                console.error(error);
            })
    }

    const takePicture = async () => {
        if (videoRef.current !== null) {
            setStatus(Status.loading);

            const image: string = getImageFromVideo(videoRef.current);

            api.sendImage(image)
                .then(
                    result => {
                        if (result) {
                            setStatus(Status.success);
                        } else {
                            setStatus(Status.error);
                            console.error('Sending image to service failed.')
                        }
                    },
                    error => {
                        setStatus(Status.error);
                        console.error(error);
                    }
                );
        }
    }

    const getImageFromVideo = (video: HTMLVideoElement): string => {
        let canvas: HTMLCanvasElement = document.createElement('canvas');

        canvas.width = video.clientWidth;
        canvas.height = video.clientHeight;

        let ctx = canvas.getContext('2d');

        if (ctx === null) {
            setStatus(Status.error);
            throw new Error('Could not get canvas rendering context')
        }

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL('image/jpeg');
    }

    let deviceSelection;
    if (devices.length) {
        deviceSelection = <Dropdown
            name={'Kamara ändern'}
            options={devices.map(device => {
                return {
                    key: device.deviceId,
                    label: device.label
                }
            })}
            callback={setSelectedDevice}
        />
    }

    return (
        <div className={'video'}>
            <video ref={videoRef}>
                Bitte die Kamera freigeben
            </video>

            <StatusBanner status={status}/>

            <button onClick={takePicture}>Bild aufnehmen</button>

            {deviceSelection ?? ''}
        </div>
    );
}
