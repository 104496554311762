import {JSX, useContext, useEffect, useState} from "react";
import {Member} from "../types/Member";
import * as api from "../utilities/Api";
import {Status} from "../types/Status";
import StatusBanner from "../components/StatusBanner";
import MemberEntry from "../components/MemberEntry";
import MemberForm from "../components/MemberForm";
import {useNavigate} from "react-router-dom";
import {AuthenticationContextType} from "../types/Authentication";
import {AuthenticationContext} from "../App";

export default function MemberList(): JSX.Element {
    const [members, setMembers] = useState<Member[]>([]);
    const [status, setStatus] = useState<Status>(Status.loading);

    const {authentication} = useContext<AuthenticationContextType>(AuthenticationContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (authentication !== null) {
            api.loadMembers(authentication.jwt)
                .then(
                    members => {
                        if (members.length > 0) {
                            setMembers(members);
                            setStatus(Status.ready);
                        } else {
                            setStatus(Status.noResults);
                        }
                    },
                    error => {
                        setStatus(Status.error);
                        console.error(error);
                    }
                );
        }
    }, [authentication]);

    const deleteMember = (member: Member): void => {
        setStatus(Status.loading);
        api.deleteMember(member, authentication?.jwt ?? '')
            .then(
                result => {
                    if (!result) {
                        setStatus(Status.error);
                        console.error('Deleting the member failed');
                    } else {
                        // remove member from list
                        setMembers(
                            members.filter(value => value.name !== member.name)
                        );
                        setStatus(Status.success);
                    }
                },
                error => {
                    setStatus(Status.error);
                    console.error(error);
                }
            );
    }

    const addMember = (member: Member): void => {
        setStatus(Status.loading);
        api.addMember(member, authentication?.jwt ?? '')
            .then(
                result => {
                    if (!result) {
                        setStatus(Status.error);
                        console.error('Creating the member failed');
                    } else {
                        // add member to list
                        setMembers(
                            members.concat(member)
                        );
                        setStatus(Status.success);
                    }
                },
                error => {
                    setStatus(Status.error);
                    console.error(error);
                }
            );
    }

    let memberList;
    if (members.length > 0) {
        memberList = members.map(member => {
            return <MemberEntry member={member} deleteCallback={deleteMember} key={member.name}/>
        })
    }

    return (
        <div className={'member'}>
            <h1>Mitglieder</h1>

            <StatusBanner status={status}/>

            <div className={'memberList'}>
                {memberList ?? ''}
            </div>

            <MemberForm createCallback={addMember}/>

            <button onClick={() => navigate('/admin')}>zurück</button>
        </div>
    );
}