import './App.css';
import {MemoryRouter, Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import Login from "./views/Login";
import Admin from "./views/Admin";
import MemberList from "./views/MemberList";
import React, {useState} from "react";
import {Authentication, AuthenticationContextType} from "./types/Authentication";
import SummaryCreation from "./views/SummaryCreation";

// @ts-ignore the default value is overridden by the usage below
export const AuthenticationContext = React.createContext<AuthenticationContextType>(null);

export default function App() {

    const [authentication, setAuthentication] = useState<Authentication>(null);

    return (
        <div className={'App'}>
            <AuthenticationContext.Provider value={{authentication, setAuthentication}}>
                <MemoryRouter>
                    <Routes>
                        <Route path={'/'} element={<Home />} />
                        <Route path={'/login'} element={<Login />} />
                        <Route path={'/admin'} element={<Admin />} />
                        <Route path={'/member'} element={<MemberList />} />
                        <Route path={'/summary'} element={<SummaryCreation />} />
                    </Routes>
                </MemoryRouter>
            </AuthenticationContext.Provider>
        </div>
    );
}
