import {Day} from "../types/Day";
import {DateTime} from "luxon";

type DayProps = {
    day: Day,
    toggleImageCallback: (imageId: number) => void
}

export default function DayImages({day, toggleImageCallback}: DayProps) {

    const imageList = day.images.map(image => {
        return (
            <div className={'image'} key={image.id}>
                <img src={image.image} alt={'Bild vom ' + DateTime.fromSeconds(image.timestamp).toISODate()}/>

                <p>
                    <label>
                        <input type={'checkbox'} onChange={() => toggleImageCallback(image.id)}/>
                        Bild auswählen
                    </label>
                </p>

                <details>
                    <summary>Details</summary>
                    <table>
                        <tbody>
                            <tr>
                                <td>IP:</td>
                                <td>{image.ip}</td>
                            </tr>

                            <tr>
                                <td>User-Agent</td>
                                <td>{image.user_agent}</td>
                            </tr>

                            <tr>
                                <td>Zeit</td>
                                <td>{DateTime.fromSeconds(image.timestamp).toFormat('hh:mm')}</td>
                            </tr>
                        </tbody>
                    </table>
                </details>
            </div>
        );
    })

    return (
        <article className={'day'}>
            <header>{day.title}</header>

            {imageList}
        </article>
    );
}
