import {JSX, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as api from "../utilities/Api";
import {Day} from "../types/Day";
import {groupImagesByDay} from "../utilities/ImageGrouping";
import DayImages from "../components/DayImages";
import {Status} from "../types/Status";
import StatusBanner from "../components/StatusBanner";
import {AuthenticationContextType} from "../types/Authentication";
import {AuthenticationContext} from "../App";


export default function Admin(): JSX.Element {

    const [days, setDays] = useState<Day[]>([]);
    const [selectedImages, setSelectedImages] = useState<number[]>([]);
    const [status, setStatus] = useState<Status>(Status.loading);

    const {authentication} = useContext<AuthenticationContextType>(AuthenticationContext);

    const navigate = useNavigate();

    const loadImages = () => {
        if (authentication !== null) {
            api.loadImages(authentication.jwt)
                .then(
                    images => {
                        if (images.length > 0) {
                            setDays(groupImagesByDay(images));
                            setStatus(Status.ready);
                        } else {
                            setDays([]);
                            setStatus(Status.noResults);
                        }
                    },
                    error => {
                        setStatus(Status.error);
                        console.error(error);
                    });
        }
    }

    useEffect(() => {
        loadImages();
    }, [authentication]);

    const toggleImageSelection = (id: number): void => {
        setSelectedImages(selectedImages => {
            if (selectedImages.includes(id)) {
                return selectedImages.filter(selected => selected !== id);
            } else {
                return selectedImages.concat(id);
            }
        })
    };

    const deleteImages = (): void => {
        setStatus(Status.loading);

        let requests = setSelectedImages.length;
        for (const imageId of selectedImages) {
            api.deleteImage(imageId, authentication?.jwt ?? '').then(
                result => {
                    if (result) {
                        requests--;

                        if(requests === 0) {
                            // when all deletions are successful, reset the selection and reload the images
                            setSelectedImages([]);
                            loadImages();
                        }
                    }
                },
                error => {
                    setStatus(Status.error);
                    console.error(error);
                }
            )
        }
    }

    const createSummary = (): void => {
        navigate('/summary', {state: {selectedImages}})
    }

    const dayList = days.map(day => {
        return <DayImages day={day} toggleImageCallback={toggleImageSelection} key={day.title}/>
    });

    return (
        <div className={'admin'}>
            <h1>Admin</h1>

            <StatusBanner status={status} />

            {dayList}

            <nav className={'actionbar'}>
                <ul>
                    {selectedImages.length ? <li className={'selectionInfo'}>{selectedImages.length} ausgewählt</li> : ''}

                    <li>
                        <button disabled={selectedImages.length === 0} onClick={deleteImages}>Löschen</button>
                    </li>
                    <li>
                        <button disabled={selectedImages.length === 0} onClick={createSummary}>Verschicken</button>
                    </li>
                </ul>
            </nav>

            <button onClick={() => navigate('/member')}>Mitglieder</button>
            <button onClick={() => navigate('/')}>Startseite</button>
        </div>
    )
}